import { CommitteeDiscussion, CommitteeMember, CommitteeStudent } from ".";
import { DataRow } from "shared-lib";
export interface CommitteeDependency extends DataRow
{
  Id: string;
  CommitteeID: string;
}

export const DependencyDefaultInstance: Record<string, CommitteeDependency> = {
  CommitteeStudent : {
    UnitID      : "",
    FieldID     : "",
    CYAInstitutionID : "",
    StudentID   : "",
    Date        : new Date()
  } as CommitteeStudent
  ,
  CommitteeDiscussion : {
    Protocol        : "",
    DecisionTypes   : "",
    Difficulties    : "",
    RAMA            : "",
    Remarks         : "",
    StudentName     : "",
    StudentIdNumber : ""
  } as CommitteeDiscussion
  ,
  CommitteeMember : {
    FirstName : "",
    LastName  : "",
    Phone     : "",
    Email     : "",
    WorkSpace : "",
    RoleID    : ""
  } as CommitteeMember
}