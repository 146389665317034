/* eslint-disable max-len */
/* eslint-disable max-lines, max-params */
import
{
  Actions, createEffect, ofType
} from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { StorageMap } from "@ngx-pwa/local-storage";

import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import
{
  catchError, concatMap, map, mergeMap, of, pipe, switchMap
} from "rxjs";

import
{
  Address, CivilStatus,
  Committee, Communication, Id,
  Institution, Intervention, PartialData, Relative
} from "../shared/models";
import { DocumentsProtocol } from "../shared/models/documents-protocol";

import { AddressService } from "../shared/services/address.service";
import { CivilStatusService } from "../shared/services/civil-status.service";
import { CommunicationService } from "../shared/services/communication.service";
import { IdService } from "../shared/services/id.service";
import { InstitutionService } from "../shared/services/institution.service";
import { InterventionService } from "../shared/services/intervention.service";
import { ProtocolService } from "../shared/services/protocol.service";
import { RelativeService } from "../shared/services/relative.service";
import { EmployeeService } from "../shared/services/employee.service";

import * as appActions from "./app.actions";
import * as calendarActions from "../calendar/store/calendar.actions";
import * as protocolActions from "../protocol/store/protocol.actions";
import * as studentActions from "../student/store/student.actions";

import { ActionType } from "../message/message-action";
import { Calendar } from "../calendar/calendar";
import { CalendarService } from "../calendar/calendar.service";
import { MessageType } from "../message/message";
import { openConfirmDialog } from "./app.actions";
import { ExcelService } from "../shared/services/excel.service";
import { TRANSLATIONS } from "../shared/translations";
import { ClientOrganizationService } from "../shared/services/client-organization.service";
import { CONSTANTS } from "../shared/constants";
import { ClientOrganizationTypeEnum } from "../shared/enums/client-organization.enum";
import { DocumentService } from "../shared/services/document.service";
import { OptionService } from "../shared/services/option.service";
import { CommitteeService } from "../shared/services/committee.service";
import { EventTypeEnum } from "../shared/enums";


@Injectable()
export class AppEffects
{
  constructor(
    private actions$: Actions,
    private addressService: AddressService,
    private calendarService: CalendarService,
    private civilStatusService: CivilStatusService,
    private communicationService: CommunicationService,
    private idService: IdService,
    private relativeService: RelativeService,
    private interventionService: InterventionService,
    private institutionService: InstitutionService,
    private committeeService: CommitteeService,
    private EmployeeService: EmployeeService,
    private protocolService: ProtocolService,
    private storageMap: StorageMap,
    private excelService: ExcelService,
    private clientOrganizationService: ClientOrganizationService,
    private documentService:DocumentService,
    private optionService:OptionService,
    private readonly store: Store
  )
  {}

  // Relatives
  processLoadedRelatives = (personId: string) => pipe(
    concatMap((data: Relative[]) =>
      of(
        appActions.loadedRelativeSuccessfully({
          Relatives: data.map((a) =>
          {
            return {
              ...a, PersonId: personId
            };
          })
        }),
        studentActions.loadedRelativeFinish()
      )
    ),
    catchError((error: HttpErrorResponse) =>
      of(
        studentActions.loadedRelativeFailed({ Message: error.message }),
        studentActions.loadedRelativeFinish()
      )
    )
  );

  loadRelativesByPersonId$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(appActions.loadRelatives),
      switchMap((action) =>
        this.relativeService.getRelativesByPersonId(action.PersonId).pipe(
          this.processLoadedRelatives(action.PersonId)
        )
      )
    );
  });

  // Ids
  processLoadedIds = (personId: string) => pipe(
    concatMap((data: Id[]) =>
      of(
        appActions.loadedIdsSuccessfully({
          Ids: data.map((a) =>
          {
            return {
              ...a, PersonId: personId
            };
          })
        }),
        studentActions.loadedIdFinish()
      )
    ),
    catchError((error: HttpErrorResponse) =>
      of(
        studentActions.loadedIdFailed({ Message: error.message }),
        studentActions.loadedIdFinish()
      )
    )
  );

  loadIdsByPersonId$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(appActions.loadIds),
      switchMap((action) =>
        this.idService.getIDsByPersonId(action.PersonId).pipe(
          this.processLoadedIds(action.PersonId)
        )
      )
    );
  });

  // Communication
  processLoadedCommunications = (personId: string) => pipe(
    concatMap((data: Communication[]) =>
      of(
        appActions.loadedCommunicationsSuccessfully({
          Communications: data.map((a) =>
          {
            return {
              ...a, PersonId: personId
            };
          })
        }),
        studentActions.loadedCommunicationFinish()
      )
    ),
    catchError((error: HttpErrorResponse) =>
      of(
        studentActions.loadedCommunicationFailed({ Message: error.message }),
        studentActions.loadedCommunicationFinish()
      )
    )
  );

  loadCommunicationByPersonId$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(appActions.loadCommunications),
      switchMap((action) =>
        this.communicationService.getCommunicationsByPersonId(action.PersonId).pipe(
          this.processLoadedCommunications(action.PersonId)
        )
      )
    );
  });

  // CivilStatus
  processLoadedCivilStatuses = (personId: string) => pipe(
    concatMap((data: CivilStatus[]) =>
      of(
        appActions.loadedCivilStatusesSuccessfully({
          CivilStatuses: data.map((a) =>
          {
            return {
              ...a, PersonId: personId
            };
          })
        }),
        studentActions.loadedCivilStatusFinish()
      )
    ),
    catchError((error: HttpErrorResponse) =>
      of(
        studentActions.loadedCivilStatusFailed({ Message: error.message }),
        studentActions.loadedCivilStatusFinish()
      )
    )
  );

  loadCivilStatusByPersonId$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(appActions.loadcivilStatuses),
      switchMap((action) =>
        this.civilStatusService.getCivilStatusesByPersonId(action.PersonId).pipe(
          this.processLoadedCivilStatuses(action.PersonId)
        )
      )
    );
  });

  // Address
  processLoadedAddresses = (personId: string) => pipe(
    concatMap((data: Address[]) =>
      of(
        appActions.loadedAddressSuccessfully({
          Addresses: data.map((a) =>
          {
            return {
              ...a, PersonId: personId
            };
          })
        }),
        studentActions.loadedAddressFinish()
      )
    ),
    catchError((error: HttpErrorResponse) =>
      of(
        studentActions.loadedAddressFailed({ Message: error.message }),
        studentActions.loadedAddressFinish()
      )
    )
  );

  loadAddressByPersonId$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(appActions.loadAddresses),
      switchMap((action) =>
        this.addressService.getAddressesByPersonId(action.PersonId).pipe(
          this.processLoadedAddresses(action.PersonId)
        )
      )
    );
  });

  // Calendar
  processLoadedCalendars = (employeeId: number, date: Date) => pipe(
    concatMap((data: Calendar[]) =>
      of(
        appActions.loadedCalendarByEmployeeIdAndDateSuccessfully({
          Calendars: data
        }),
        appActions.serverCalledFinish({
          message: {
            Id: 0,
            Message: "Loaded Calendar entries successfully.",
            Type: MessageType.success,
            AutoClose: true
          }
        }),
        calendarActions.loadedCalendarPageFinish()
      )
    ),
    catchError((error: HttpErrorResponse) =>
      of(
        appActions.serverCalledFinish({
          message: {
            Id: 0,
            Message: error.message,
            Type: MessageType.error,
            ActionList: [{
              type: ActionType.tryAgain,
              action: appActions.tryAgainLoadCalendarByEmployeeIdAndDate({
                EmployeeId: employeeId,
                Date: date
              })
            }, {
              type: ActionType.cancel,
              action: calendarActions.loadedCalendarPageFinish()
            }]
          }
        })
      )
    )
  );

  loadCalendarByEmployeeIdAndDate$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(
        appActions.loadCalendarByEmployeeIdAndDate,
        appActions.tryAgainLoadCalendarByEmployeeIdAndDate
      ),
      switchMap((action) =>
        this.calendarService
          .getCalendarByEmployeeIdAndDate(
            action.EmployeeId,
            action.Date
          )
          .pipe(
            this.processLoadedCalendars(
              action.EmployeeId,
              action.Date
            )
          )
      )
    );
  });

  processSavedCalendarResponse = (newItem: Calendar) => pipe(
    concatMap((data: Calendar | null) =>
      of(
        appActions.addedNewCalendarSuccessfully({
          Id: data?.Id ?? 0,
          TempId: data?.TempId ?? 0
        }),
        appActions.serverCalledFinish({
          message:
          {
            Id: 0,
            Message: "Calendar entry successfully added.",
            Type: MessageType.success,
            AutoClose: true
          }
        }),
        calendarActions.loadedCalendarPageFinish()
      )
    ),
    catchError((error: HttpErrorResponse) =>
      of(
        appActions.serverCalledFinish({
          message: {
            Id: 0,
            Message: error.message,
            Type: MessageType.error,
            ActionList: [{
              type: ActionType.tryAgain,
              action: appActions.tryAgainAddNewCalendar({ NewItem: newItem })
            }, {
              type: ActionType.cancel,
              action: appActions.
                addNewCalendarFailed({ TempId: newItem.TempId })
            }]
          }
        })
      )
    )
  );

  addNewCalendar$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(
        appActions.requestAddNewCalendar,
        appActions.tryAgainAddNewCalendar
      ),
      switchMap((action) =>
        this.calendarService.addNewCalendar(action.NewItem).pipe(
          this.processSavedCalendarResponse(action.NewItem)
        )
      )
    );
  });

  // Intervention
  processLoadedIntervention = (eventType : EventTypeEnum) => pipe(
    concatMap((data: PartialData<Intervention> | null) =>
      of(
        appActions.loadedInterventionSuccessfully({
          Interventions: {
            Data: data ? data.Data : [],
            TotalRecords: data ? data.TotalRecords : 0
          } as PartialData<Intervention>,
          EventType : eventType
         }
        ),
        studentActions.loadedRelativeFinish()
      )
    ),
    catchError((error: HttpErrorResponse) =>
      of(
        appActions.loadedInterventionFailed({ Message: error.message }),
        appActions.loadedInterventionFinish()
      )
    )
  );

  loadInterventionByPersonId$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(appActions.loadInterventionByPersonId),
      switchMap((action) =>
        this.interventionService.SearchInterventionsByPerson(
          action.personId, action.eventType, action.skip, action.take).pipe(
            this.processLoadedIntervention(action.eventType)
          )
      )
    );
  });

  // Institution
  processLoadedInstitution = (personId: string) => pipe(
    concatMap((data: PartialData<Institution> | null) =>
      of(
        appActions.loadedInstitutionSuccessfully({
          Institutions: {
            Data: data ? data.Data.map((a) =>
            {
              return {
                ...a, Id: personId
              };
            }) : [],
            TotalRecords: data ? data.TotalRecords : 0
          } as PartialData<Institution>
        }),
        studentActions.loadedRelativeFinish()
      )
    ),
    catchError((error: HttpErrorResponse) =>
      of(
        appActions.loadedInstitutionFailed({ Message: error.message }),
        appActions.loadedInstitutionFinish()
      )
    )
  );

  loadInstitutionByPersonId$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(appActions.loadInstitutionByPersonId),
      switchMap((action) =>
        this.institutionService.getInstitutions(
          action.Skip, action.Take).pipe(
            this.processLoadedInstitution(action.PersonId)
          )
      )
    );
  });


  // Committees
  processLoadedCommittee = (personId: string) => pipe(
    concatMap((data: PartialData<Committee> | null) =>
      of(
        appActions.loadedCommitteeSuccessfully({
          Committees: {
            Data: data ? data.Data.map((a) =>
            {
              return {
                ...a, Id: personId
              };
            }) : [],
            TotalRecords: data ? data.TotalRecords : 0
          } as PartialData<Committee>
        }),
        studentActions.loadedRelativeFinish()
      )
    ),
    catchError((error: HttpErrorResponse) =>
      of(
        appActions.loadedCommitteeFailed({ Message: error.message }),
        appActions.loadedCommitteeFinish()
      )
    )
  );

  loadCommitteeByPersonId$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(appActions.loadCommitteeByPersonId),
      switchMap((action) =>
        this.committeeService.getCommittees(
          action.skip, action.take).pipe(
            this.processLoadedCommittee(action.personId)
          )
      )
    );
  });

  insertProtocol$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(protocolActions.insertProtocol),
      mergeMap((action: ReturnType<typeof protocolActions.insertProtocol>) =>
      {
        return this.protocolService.insertProtocol(action.protocol, action.documents).pipe(
          map((result) =>
          {
            if (!result)
            {
              throw(new Error("Update Failed"));
            }
            this.storageMap.get("unsentProtocolsList").subscribe((unsentProtocolsJSON: unknown) =>
            {
              const unsentProtocolsList: DocumentsProtocol[]
                = unsentProtocolsJSON
                  ? JSON.parse(unsentProtocolsJSON as string) as DocumentsProtocol[]
                  : [];
              const foundIndex = unsentProtocolsList.findIndex((protocol) =>
              {
                return protocol.InterventionInsertDTO.Date === action.protocol.Date;
              });

              unsentProtocolsList.splice(foundIndex, 1);
              this.storageMap.set("unsentProtocolsList", JSON.stringify(unsentProtocolsList)).subscribe();

              this.store.dispatch(
                openConfirmDialog({
                  message: {
                    Id: 0,
                    Message: TRANSLATIONS.Intervention["SavedSuccessfullyWithFiles"],
                    Type: MessageType.success
                  }
                })
              );
            });

            return protocolActions.insertProtocolSuccess({ result });
          }),
          catchError((error) =>
          {
            console.log("catchError");
            if (error == 201)
            {
              this.store.dispatch(openConfirmDialog({
                message : {
                  Id         : 0,
                  Message    : TRANSLATIONS.Intervention["SavedSuccessfullyWithoutFiles"],
                  Type       : MessageType.confirm,
                  ActionList : [{
                    type     : ActionType.cancel,
                    btnLabel: TRANSLATIONS.Intervention["Approval"]
                  }]
                }
              }));

              // TODO change from any.
              return of(protocolActions.insertProtocolFailure({ error }));
            }
            else
            {
              this.store.dispatch(openConfirmDialog({
                message: {
                  Id: 0,
                  Message: TRANSLATIONS.Intervention["ErrorOccurred"],
                  Type: MessageType.error,
                  ActionList: [{
                    type: ActionType.cancel,
                    btnLabel: TRANSLATIONS.Intervention["Approval"]
                  }]
                }
              }));
              this.storageMap.get("unsentProtocolsList").subscribe((unsentProtocolsJSON: unknown) =>
              {
                const unsentProtocolsList: DocumentsProtocol[]
                  = unsentProtocolsJSON
                    ? JSON.parse(unsentProtocolsJSON as string) as DocumentsProtocol[]
                    : [];

                const foundIndex = unsentProtocolsList.findIndex((protocol) =>
                {
                  return protocol.InterventionInsertDTO.Date === action.protocol.Date;
                });

                if (foundIndex === -1)
                {
                  unsentProtocolsList.push({
                    InterventionInsertDTO: action.protocol, documents: action.documents
                  });
                  this.storageMap.set("unsentProtocolsList", JSON.stringify(unsentProtocolsList)).subscribe();
                }
              });

              // TODO change from any.
              return of(protocolActions.insertProtocolFailure({ error }));
            }
          }
          )
        );
      }
      )
    );
  }
  );

  // Excel

  processExportExcel = () => pipe(
    concatMap(() =>
      of(
        appActions.finishExportExcel(),
        openConfirmDialog({
          message: {
            Id: 0,
            Message: TRANSLATIONS.Table["ExportExcelSuccessfully"],
            Type: MessageType.success,
            AutoClose: true
          }
        })
      )
    ),
    catchError((error: HttpErrorResponse) =>
      of(
        appActions.finishExportExcel(),
        openConfirmDialog({
          message: {
            Id: 0,
            Message: error.message,
            Type: MessageType.error
          }
        })
      )
    )
  );

  startExportExcel$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(appActions.startExportExcel),
      switchMap((action) =>
        this.excelService.exportAsExcelFile(
          action.json, action.headers, action.sheetName,
          action.fileName).pipe(
            this.processExportExcel()
          ))
    );
  });

  loadConnectedOptions$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(appActions.loadAddressOptions),
      mergeMap((action: ReturnType<typeof appActions.loadAddressOptions>) =>
        this.optionService.getConnectedOptions(action.fieldName,AddressService.url, action.connected)
        .pipe(
          map(optionsPartial => appActions.loadAddressOptionsSuccess({
            fieldName: action.fieldName, options: optionsPartial && optionsPartial.Data ? optionsPartial.Data : []
          })),
          catchError((error) => of(appActions.loadAddressOptionsFailure({ error })))
        )
      )
    );
  }
  );
  loadCheckIfBuildingOrEntranceExists$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(appActions.checkIfBuildingOrEntranceExists),
      mergeMap((action: ReturnType<typeof appActions.checkIfBuildingOrEntranceExists>) => this.addressService.checkIfBuildingOrEntranceExists(action.fieldName, action.entranceNumber, action.cityCode,action.streetCode,action.buildingNumber)
        .pipe(
          map(result => appActions.checkIfBuildingOrEntranceExistsSuccess({
            isExist: result
          })),
          catchError((error) => of(appActions.checkIfBuildingOrEntranceExistsFailure({ error })))
        )
      )
    );
  }
  );
  loadNeighborhoodAndEntrance$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(appActions.loadNeighborhoodAndEntrance),
      mergeMap((action: ReturnType<typeof appActions.loadNeighborhoodAndEntrance>) => this.addressService.getNeighborhoodAndEntrance( action.cityCode,action.streetCode,  action.buildingNumber)
        .pipe(
          map(result => appActions.loadNeighborhoodAndEntranceSuccess({
            AddressUpdateData: result
          })),
          catchError((error) => of(appActions.loadNeighborhoodAndEntranceFailure({ error })))
        )
      )
    );
  }
  );

  loadClientOrganization$ = createEffect(() =>
  {
    return this.actions$.pipe(
      ofType(appActions.loadClientOrganization),
      mergeMap(() => this.clientOrganizationService.getClientOrganization()
        .pipe(
          concatMap((res) =>
          {
            this.clientOrganizationService.clientOrganization = res == ClientOrganizationTypeEnum.EPS ? CONSTANTS.ClientOrganizationType.EPS : CONSTANTS.ClientOrganizationType.CYA;
          return  of(appActions.loadClientOrganizationSuccess(
              { clientOrganization: res }),
              appActions.loadClientOrganizationFinished());
          }),
          catchError((error: HttpErrorResponse) => of(appActions.loadClientOrganizationFailure({ error: error.message }),
            appActions.loadClientOrganizationFinished()
          ))
        )
      )
    );
  }
  );

  loadEmployeeId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.loadEmployeeId),
      mergeMap(() =>
        this.EmployeeService.getEmployeeId().pipe(
          concatMap((res) => {
            if (res !== null) {
              return of(
                appActions.loadEmployeeIdSuccess({ employeeId: res }),
                appActions.loadEmployeeIdFinished()
              );
            } else {
              return of(appActions.loadEmployeeIdFailure({ error: 'EmployeeId is null' }));
            }
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              appActions.loadEmployeeIdFailure({ error: error.message }),
              appActions.loadEmployeeIdFinished()
            )
          )
        )
      )
    )
  );

    //Load Documents
    loadDocument$ = createEffect(() =>
    {
      return this.actions$.pipe(ofType(appActions.loadDocument),
        concatMap((action) =>
          this.documentService.getDocumentById(
            action.documentId)
            .pipe(this.processLoadedDocument())
        )
      );
    });

    processLoadedDocument = () => pipe(
      concatMap((documentBlob: Blob | null) =>
      {
        if (!documentBlob)
        {
          throw { message: TRANSLATIONS.File["LoadDocumentFailed"] };
        }

        return of(
          appActions.loadDocumentSuccess({
            documentUrl: window.URL.createObjectURL(documentBlob!)
          })
          ,
          appActions.loadDocumentFinish()
        );
      }
      ),
      catchError((error: HttpErrorResponse) =>
        of(
          appActions.serverCalledFinish({
            message: {
              Id: 0,
              Message: error.message,
              Type: MessageType.error
            }
          }),
          appActions.loadDocumentFailure({error:error.message}),
          appActions.loadDocumentFinish()
        )
      )
    );

}