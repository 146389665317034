/* eslint-disable max-lines */
import { createEntityAdapter } from "@ngrx/entity";

import
{
  Action, createReducer, on
} from "@ngrx/store";

import { Calendar } from "../calendar/calendar";
import { Message } from "../message/message";

import
{
  Address,
  CivilStatus,
  Committee,
  Communication,
  Id,
  Institution,
  Intervention,
  Relative
} from "../shared/models";

import * as appActions from "./app.actions";

import
{
  DataState, State
} from "./state";

import { CalendarState } from "./entity-states/calendar.state";
import { MessageState } from "./entity-states/message.state";
import { User } from "../shared/models";
import { UserState } from "./entity-states/user.state";

import { AddressState } from "./entity-states/address.state";
import { CivilStatusState } from "./entity-states/civil-status.state";
import { CommunicationState } from "./entity-states/communication.state";
import { IdState } from "./entity-states/id.state";
import { RelativeState } from "./entity-states/relative.state";
import { InterventionState } from "./entity-states/intervention.state";
import { InstitutionState } from "./entity-states/institution.state";
import { CommitteeState } from "./entity-states/committee.state";
import { CONSTANTS } from "../shared/constants";


const adapterMessage = createEntityAdapter<Message>({ selectId: m => m.Id });
const adapterAddress = createEntityAdapter<Address>({ selectId: a => a.Id });
const adapterCivilStatus = createEntityAdapter<CivilStatus>({ selectId: c => c.Id });
const adapterCommunication = createEntityAdapter<Communication>({ selectId: c => c.Id });
const adapterId = createEntityAdapter<Id>({ selectId: i => i.Id });
const adapterRelative = createEntityAdapter<Relative>({ selectId: r => r.Id });
const adapterCalendar = createEntityAdapter<Calendar>({ selectId: c => c.Id });
const adapterUser = createEntityAdapter<User>({ selectId: u => u.Id });
const adapterIntervention = createEntityAdapter<Intervention>({ selectId: u => u.Id });
const adapterInstitution = createEntityAdapter<Institution>({ selectId: u => u.Id });
const adapterCommittee = createEntityAdapter<Committee>({ selectId: u => u.Id });

const messageInitialState: MessageState = adapterMessage.getInitialState({
  NextId: 1
});
const addressInitialState: AddressState = adapterAddress.getInitialState({});
const civilStatusInitialState: CivilStatusState = adapterCivilStatus.getInitialState({});
const communicationInitialState: CommunicationState = adapterCommunication.getInitialState({});
const idInitialState: IdState = adapterId.getInitialState({});
const relativeInitialState: RelativeState = adapterRelative.getInitialState({});
const calendarInitialState: CalendarState = adapterCalendar.getInitialState({
  TempId: -1
});
const userInitialState: UserState = adapterUser.getInitialState({});
const interventionInitialState: InterventionState =
  adapterIntervention.getInitialState({
    TotalRecords: 0
  });
const institutionInitialState: InstitutionState =
  adapterInstitution.getInitialState({
    TotalRecords: 0
  });
const committeeInitialState: CommitteeState =
  adapterCommittee.getInitialState({
    TotalRecords: 0
  });


export const initialState: State = {
  Data: {
    User: userInitialState,
    Addresses: addressInitialState,
    CivilStatuses: civilStatusInitialState,
    Communications: communicationInitialState,
    Ids: idInitialState,
    Relatives: relativeInitialState,
    Calendars: calendarInitialState,
    Messages: messageInitialState,
    Interventions: {
      regular : interventionInitialState,
      warning : interventionInitialState,
      emergency : interventionInitialState
    },
    Institutions: institutionInitialState,
    Committees: committeeInitialState,
    CanExportExcel: true,
    AddressOptions: {},
    ClientOrganization: null,
    ClientOrganizationIsReturn: false,
    EmployeeId: null,
    IsBuildingOrEntranceExists: null,
    AddressUpdateData: null,
    Document: null,
    IsDocumentLoading: false,
    CommitteeId: null,
  }
};

const dataReducer = createReducer(
  initialState.Data,

  // CivilStatuses
  on(appActions.loadedCivilStatusesSuccessfully, (state, result) => ({
    ...state,
    CivilStatuses: adapterCivilStatus.addMany(result.CivilStatuses, state.CivilStatuses)
  })),

  // Address
  on(appActions.loadedAddressSuccessfully, (state, result) => ({
    ...state,
    Addresses: adapterAddress.addMany(result.Addresses, state.Addresses)
  })),

  // Communication
  on(appActions.loadedCommunicationsSuccessfully, (state, result) => ({
    ...state,
    Communications: adapterCommunication.addMany(result.Communications, state.Communications)
  })),

  // Id
  on(appActions.loadedIdsSuccessfully, (state, result) => ({
    ...state,
    Ids: adapterId.addMany(result.Ids, state.Ids)
  })),

  // Relative
  on(appActions.loadedRelativeSuccessfully, (state, result) => ({
    ...state,
    Relatives: adapterRelative.addMany(result.Relatives, state.Relatives)
  })),

  // Calendar
  on(appActions.loadedCalendarByEmployeeIdAndDateSuccessfully, (state, result) => ({
    ...state,
    Calendars: adapterCalendar.addMany(result.Calendars, state.Calendars)
  })),

  on(appActions.requestAddNewCalendar, (state, result) =>
  {
    const itemToAdd = {
      ...result.NewItem,
      Id: state.Calendars.TempId,
      TempId: state.Calendars.TempId
    };
    const newState = {
      ...state,
      Calendars: adapterCalendar.addOne(itemToAdd, state.Calendars)
    };

    return {
      ...newState,
      Calendars: {
        ...newState.Calendars, TempId: state.Calendars.TempId - 1
      }
    };
  }),
  on(appActions.addedNewCalendarSuccessfully, (state, result) =>
  {
    return {
      ...state,
      Calendars: adapterCalendar.updateOne(
        {
          id: result.TempId,
          changes: {
            ...state.Calendars.entities[result.TempId],
            Id: result.Id
          }
        },
        state.Calendars
      )
    };

    /*
     * Return {
     *   ...state,
     *   calendars: Object.values(state.calendars.entities).map((c) =>
     *     c?.id === result.tempId ? { ...c, id: result.id } : c
     *   ),
     * };
     */
  }),
  on(appActions.addNewCalendarFailed, (state, result) =>
  {
    return {
      ...state,
      Calendars: adapterCalendar.removeOne(result.TempId, state.Calendars)
    };
  }),
  on(appActions.serverCalledFinish, (state, result) =>
  {
    const itemToAdd = {
      ...result.message, id: state.Messages.NextId
    };
    const newState = {
      ...state,
      Messages: adapterMessage.addOne(itemToAdd, state.Messages)
    };

    return {
      ...newState,
      Messages: {
        ...newState.Messages, NextId: state.Messages.NextId + 1
      }
    };
  }),

  // Message
  on(appActions.messageRemoved, (state, result) =>
  {
    return {
      ...state,
      Messages: adapterMessage.removeOne(result.messageId, state.Messages)
    };
  }),
  on(appActions.openConfirmDialog, (state, result) =>
  {
    const itemToAdd = {
      ...result.message, id: state.Messages.NextId
    };
    const newState = {
      ...state,
      Messages: adapterMessage.addOne(itemToAdd, state.Messages)
    };

    return {
      ...newState,
      Messages: {
        ...newState.Messages, NextId: state.Messages.NextId + 1
      }
    };
  }),
  on(appActions.addedRoleUser, (state, result) =>
  {
    return {
      ...state,
      User: adapterUser.addOne({
        Id: 1, Role: result.role
      }, state.User)
    };
  }),

  /// interventions
  on(appActions.loadedInterventionSuccessfully, (state, result) =>
  {
    const newState = {
      ...state,
      Interventions:{ 
        ...state.Interventions,
      [result.EventType] : adapterIntervention.setAll(
        result.Interventions.Data, state.Interventions[result.EventType])}
    };

    return {
      ...newState,
      Interventions: {
        ...newState.Interventions,
        [result.EventType] :{
          ...newState.Interventions[result.EventType],
          TotalRecords: result.Interventions.TotalRecords
        }
      }
    };
  }),
  on(appActions.clearInterventionByPersonId, (state, result) =>
    {
      return {
        ...state,
        Interventions: {
          regular : interventionInitialState,
          warning : interventionInitialState,
          emergency : interventionInitialState
        }
      };
    }),
  /// institutions
  on(appActions.loadedInstitutionSuccessfully, (state, result) =>
  {
    const newState = {
      ...state,
      Institutions: adapterInstitution.setAll(
        result.Institutions.Data, state.Institutions)
    };

    return {
      ...newState,
      Institutions: {
        ...newState.Institutions,
        TotalRecords: result.Institutions.TotalRecords
      }
    };
  }),

  /// committee
  on(appActions.loadedCommitteeSuccessfully, (state, result) =>
  {
    const newState = {
      ...state,
      Committees: adapterCommittee.setAll(
        result.Committees.Data, state.Committees)
    };

    return {
      ...newState,
      Committees: {
        ...newState.Committees,
        TotalRecords: result.Committees.TotalRecords
      }
    };
  }),

  /// excel
 on(appActions.startExportExcel, (state) =>
  (
    {
      ...state,
      CanExportExcel: false
 })),
 on(appActions.finishExportExcel, (state) =>
  ({
    ...state,
    CanExportExcel: true
 })),
 on(appActions.updateCanExportExcel, (state, result) =>
 ({
   ...state,
    CanExportExcel: result.canExport
 })),

on(appActions.loadAddressOptions, (state, action) =>
    {
      // you might want to set a loading state here
      return { ...state };
}),
on(appActions.loadAddressOptionsSuccess, (state, action) =>
  {
    return {
      ...state,
      AddressOptions: {
        ...state.AddressOptions,
        [action.fieldName]: action.options
      }
    };
}),
on(appActions.loadAddressOptionsFailure, (state, { error }) =>
  {
    return {
      ...state,
      error: error
    };
}),
on(appActions.removeFieldOptions, (state, action) =>
  {
    return {
      ...state,
      AddressOptions: {
        ...state.AddressOptions,
        [action.fieldName]: []
      }
    };
  }),
  on(appActions.checkIfBuildingOrEntranceExistsSuccess, (state, result) =>
  ({
    ...state,
    IsBuildingOrEntranceExists: result.isExist
  })),
  on(appActions.removeIsBuildingOrEntranceExists, (state, result) =>
  ({
    ...state,
    IsBuildingOrEntranceExists: null
  })),

  on(appActions.checkIfBuildingOrEntranceExistsFailure, (state, { error }) =>
  {
    return {
      ...state,
      error: error
    };
  }),
  on(appActions.loadNeighborhoodAndEntranceSuccess, (state, result) =>
  ({
    ...state,
    AddressUpdateData: result.AddressUpdateData
  })),

  on(appActions.loadNeighborhoodAndEntranceFailure, (state, { error }) =>
  {
    return {
      ...state,
      error: error
    };
  }),
  on(appActions.removeAddressUpdateData, (state, result) =>
  ({
    ...state,
    AddressUpdateData: null
  })),
  on(appActions.loadClientOrganizationSuccess, (state, result) =>
({
  ...state,
  ClientOrganization: result.clientOrganization
  })),

  on(appActions.loadClientOrganizationFinished, (state) =>
  ({
    ...state,
    ClientOrganizationIsReturn: true
  })),


  on(appActions.loadClientOrganizationFailure, (state, { error }) =>
  {
    return {
      ...state,
      error: error
    };
  }),

  /// Load EmployeeId
     on(appActions.loadEmployeeIdSuccess, (state, result) =>
     ({
      ...state,
      EmployeeId: result.employeeId
      })),
    
      on(appActions.loadEmployeeIdFinished, (state) =>
      ({
        ...state
      })),
        
      on(appActions.loadEmployeeIdFailure, (state, { error }) =>
      {
        return {
          ...state,
          error: error
        };
      }),

  /// Load Document
    on(appActions.loadDocument, (state): DataState =>
    {
      return {
        ...state,
        IsDocumentLoading: true
      };
    }),
    on(appActions.chooseCommittee, (state, result): DataState =>
    {
      return {
        ...state,
        CommitteeId: result.committeeId
      };
    }),
  on(appActions.loadDocumentSuccess, (state, result): DataState =>
  {
    return {
      ...state,
    Document: result.documentUrl
    };
  }),  
  on(appActions.loadDocumentFailure, (state, { error }) =>
  {
    return {
      ...state,
      Document: CONSTANTS.Document.NO_FILE,
      error: error
    };
  }),
  on(appActions.loadDocumentFinish, (state): DataState =>
  {
    return {
      ...state,
      IsDocumentLoading: false
    };
  }),
  on(appActions.clearLoadDocument, (state) => ({
    ...state,
    Document: null,
  })),
);

export function appReducer(
  state = initialState.Data,
  action: Action
): DataState
{
  return dataReducer(state, action);
}