export interface User
{
  Id: number;
  Role: UserRole;
}

export enum UserRole
{
  Admin,
  Aaa,
  Bbb
}