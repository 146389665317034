export class CommitteeSearch
{
  CommitteeId!: string;
  
  Type!: string;

  FromDate!: Date;

  ToDate!: Date;
  
  Institution!: string;
  
  Student!: string;
  
  Employee!: string;
  
  CYAInstitution!: string;
}