import { HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Committee, CommitteeInsert, CommitteeSearch, PartialData } from "../models";
import { Observable, catchError } from "rxjs";
import { ErrorHandlerCreator, ResponseErrorHandler } from "./response-error-handler.service";
import { APIService, unwrapResponse } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class CommitteeService {
  static readonly url = "Committee";

  private handleError: ErrorHandlerCreator;

  constructor(private readonly api: APIService,
    private readonly responseErrorHandler: ResponseErrorHandler)
  {
    this.handleError = this.responseErrorHandler.createErrorHandler("CommitteeService");
  }

  getCommittees(skip: number, take: number):
    Observable<PartialData<Committee> | null>
  {
    const options = { params: new HttpParams().set("skip", skip).set("take", take) };

    return this.api.get<PartialData<Committee>>(`${CommitteeService.url}`, options).pipe(
      unwrapResponse(),
      catchError(this.handleError("getCommittees", null))
    );
  }

  searchCommittees(committeeSearch: CommitteeSearch, skip: number, take: number):
    Observable<PartialData<Committee> | null>
  {
    let params = new HttpParams().set("skip", skip).set("take", take);

    if (committeeSearch)
    {
      for (const key of Object.keys(committeeSearch))
      {
        if (key.toLowerCase().includes("date"))
        {
          params = params.set(key,
            (new Date(committeeSearch[
              key as keyof CommitteeSearch])).toISOString());
        }
        else
        {
          const value = committeeSearch[key as keyof CommitteeSearch]
          if(value){
            params = params.set(key, value.toString());
          }         
        }
      }
    }

    const options = { params: params };

    return this.api.get<PartialData<Committee> | null>(`${CommitteeService.url}/search`, options).pipe(
      unwrapResponse(),
      catchError(this.handleError("searchCommittees", null))
    );
  }
  
  getCommitteeById(id: string):
    Observable<CommitteeInsert | null>
  {
    return this.api.get<CommitteeInsert>(`${CommitteeService.url}/${id}`).pipe(
      unwrapResponse(),
      catchError(this.handleError("getCommitteeById", null))
    );
  }  
  
  insertCommittee(committee: CommitteeInsert):
    Observable<boolean | null>
  {
    return this.api.post<boolean | null>(`${CommitteeService.url}/UpdateCommittee`, committee).pipe(
      unwrapResponse(),
      catchError(this.handleError("insertCommittee", null))
    );
  }
}
